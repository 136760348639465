.DefaultButton {
  position: relative;
  padding: 15px 30px;
  border-radius: 7px;
  background-image: linear-gradient(180deg,#000000,#000000);
  box-shadow: inset 0 5px 11px -3px #ffffff, inset 0 -4px 15px -3px rgb(0 0 0 / 47%), 0 2px 0 0 #000000, 0 13px 41px 0 rgb(71 102 225 / 17%), 0px 8px 25px #ffffff;
  transition: 0.2s;
  color: #fff;
  line-height: 162%;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  &:hover {
    filter: brightness(118%);
    transform: scale(1.02);
  }
}
