@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'RainshowCondensed';
  src: url('/fonts/RainshowCondensed.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'RainshowCondensed';
  src: url('/fonts/RainshowCondensed.otf');
  font-weight: normal;
}

body {
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  color: white;
  background-color: #000000;
  background-image:linear-gradient(to right, rgb(145, 0, 177), rgb(40, 1, 54));
  

  * {
    box-sizing: border-box;
  }
}

body::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
  background-color: #c214d523;
	border: 0;
}
